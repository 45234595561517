<template>
  <div id="departments">
    <div class="heading text-6xl font-semibold">Departments</div>
    <img class="mt-20" src="../../assets/images/departments.png" alt="" />

    <div class="departments_body mt-20 w-full">
      <div class="item flex items-end w-full">
        <div class="w-1/6 item_top">
          <div class="text-6xl sub_headings mb-5">18%</div>
          <div>
            In Q2 2021, 18% of all <span>investments</span> made in Nigeria came into
            Nasarawa State.
          </div>
        </div>
        <div class="w-10/12 ml-10 item_desc">
          <div class="sub_headings text-4xl mb-5">
            <b>Investment</b> Promotion and Facilitation
          </div>
          <div class="text-xl">
            <div>
              <p>
                The Investment Promotion unit is Nasarawa State’s medium of interface with
                all public and private entities interested in directing investment into
                Nasarawa State.
              </p>
              <p class="my-5">
                In Q2 2021, 18% of all investments made in Nigeria came into Nasarawa
                State. This is a testament to our vision of becoming one of the top
                competitive states in the country.
              </p>
              <p>
                Our main objective is to promote Nasarawa state as a prime destination for
                local and foreign investment, and we achieve this by;
              </p>
            </div>
            <div class="mt-5">
              <li>
                Aiding the government secure development finance from multilateral and
                bilateral development partners and private investors - both domestic and
                foreign – through diverse financing options;
              </li>
              <li>Maintaining liaison between investors and the state government;</li>
              <li>
                Providing and disseminating up-to-date information on incentives available
                for investors;
              </li>
              <li>
                Organizing and monitoring all investment promotion activities in Nasarawa
                State.
              </li>
            </div>
          </div>
        </div>
      </div>

      <div class="item flex items-end w-full mt-16">
        <div class="w-1/6 item_top">
          <div class="text-6xl sub_headings mb-5">5+</div>
          <div>Over five key projects completed in 2021</div>
        </div>
        <div class="w-10/12 ml-10 item_desc">
          <div class="sub_headings text-4xl mb-5"><b>Infrastructure </b>Finance & PPP</div>
          <div class="text-xl">
            <div>
              <p>
                Nasarawa State is committed on all fronts to developing an unrivalled
                infrastructural framework to stir growth and commercialization in the
                state. Our proximity to the Federal Capital Territory, Abuja, presents us
                with a rare opportunity worth capitalizing on. By relying on the strength
                of Public-Private Partnerships, we intend to take full advantage of this
                geographical advantage.OUt task is structured to deliver PPP project that align with goverment development projects.
              </p>
              <p class="my-5">Some of Our Projects Include:</p>
            </div>

            <div class="mt-5">
              <!-- <div class="font-bold mb-5">Completed Projects</div> -->
              <li>
                PPP on Waste Recycling and Waste to Energy at Auta Balefi in Karu Local
                Government Area of Nasarawa State
              </li>
              <li>
                Joint Venture Development of the 100 Hectares Peninsular Housing Project
              </li>
              <li>
                Concession of the operations and management of DASH Laboratory Services
              </li>
              <li>Concession of DASH Radiology Services</li>
              <li>Concession of DASH Mortuary Services</li>
            <!-- </div>
            <div class="mt-5"> -->
              <!-- <div class="font-bold mb-5">Ongoing Projects</div> -->
              <li>
                Joint Venture Development of the Nasarawa Technology Village at
                Racecourse, Karu LGA, Nasarawa State (Unsolicited)
              </li>
              <li>Concession of the Nasarawa Bus Terminals (Solicited)</li>
              <li>
                PPP on the set up and management of a Nasarawa Transport Company
                (Solicited)
              </li>
              <li>
                Joint Venture Development of a Cassava Processing Park (Unsolicited)
              </li>
              <li>
                PPP on the development of a Mineral Aggregation and Processing Plant
                (Solicited)
              </li>
            </div>
          </div>
        </div>
      </div>

      <div class="item flex items-end w-full mt-16">
        <div class="w-1/6 item_top">
          <div class="text-6xl sub_headings mb-5">3+</div>
          <div>
            Reforms-initiated to improve the business environment in Nasarawa State.
          </div>
        </div>
        <div class="w-10/12 ml-10 item_desc">
          <div class="sub_headings text-4xl mb-5"><b>Business </b> Reforms Department</div>
          <div class="text-xl">
            <div>
              <p>
                Our business reforms team works with investors and government to unlock the barriers 
                to doing business in the state. we aslo serve as the nasarawa enabling 
                business environment council which is chaired by the governor.
                <!-- Our responsibility is to render strategic consult to the state government
                to create an enabling environment for business and investment in the
                State. Our main goal is to advise and assist the government in creating an
                enabling environment for business and investment to foster social and
                economic growth and development in the State. -->
              </p>
              <p class="my-5">
                Some of the reforms-initiated in terms of improving the business
                environment in Nasarawa State are:
              </p>
            </div>

            <div class="mt-5">
              <div class="font-bold mb-5">Enactment of enabling laws</div>
              <li>Public Procurement Law</li>
              <li>
                Mortgage Foreclosure Law & Nasarawa Investment Law Streamlining Government
                Procedures
              </li>
              <li>
                Procedures, time, and cost for obtaining approvals and permits have been
                streamlined to improve efficiency across different MDAs.
              </li>
            </div>
            <div class="mt-5">
              <div class="font-bold mb-5">Building & Strengthening Institutions</div>
              <li>
                Nasarawa Governance Result and Efficiency Mechanism (N-GREM) is an exceutive order 
                inititated provide increaded transparency.
                <!-- Nasarawa Governance Result and Efficiency Mechanism (N-GREM) has been
                initiated to provide increased transparency and accountability in the
                conduct of Government business. -->
              </li>
            </div>
          </div>
        </div>
      </div>

      <div class="item flex items-end w-full mt-16">
        <div class="w-1/6 item_top">
          <div class="text-6xl sub_headings mb-5">5+</div>
          <div>
            The Strategy & Innovation Department is set up to promote better functioning of the organization, provide focus and effective approach.
          </div>
        </div>
        <div class="w-10/12 ml-10 item_desc">
          <div class="sub_headings text-4xl mb-5"><b>STRATEGY</b> AND INNOVATION DEPARTMENTS</div>
          <div class="text-xl">
            <div>
              <p>
                The Strategy & Innovation Department is set up to promote better functioning of the organization, 
                provide focus and effective approach through the use of innovations in its strategy to deliver 
                greater value, redefine channels to keep up the pace of continuous changes and the threat of
                disruptions which is a new normal.
              </p>
              <p class="my-4">
                The Strategy & Innovation Department built and maintain partnerships with Ministries, 
                Agencies, Departments and private sector champions in order to gather current 
                business knowledge and design innovative, go-to market strategies of great value 
                to the state and investors alike and spur growth, driving innovations across the organization.
              </p>
              
            </div>

            <div class="mt-5">
              <div class="font-bold mb-5">The unit conducts</div>
              <li>Fostering innovative strategies, eliminating obstacles and managing risk.</li>
              <li>
                Conduct research, modelling and forecast for management and other end-users.
              </li>
              <li>
                Collection and connecting ideas to improve service delivery.
              </li>
              <li>
                Develop models and simulation of the model to solve problems.
              </li>
              <li>
                Monitor and evaluate effectiveness of sustainability programs.
              </li>
              <li>
                Develop or execute strategies to address issues such as challenges in MDAs in the state.
              </li>
              <li>
                Develop, oversee the development of sustainable evaluation, monitoring and information data gathering systems.
              </li>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script src="./departments.page.js"></script>
<style src="./departments.page.css" scoped lang="css"></style>
